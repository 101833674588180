<template>
    <div id="app">
        <notifications />
        <component :is="layout" v-if="isLoaded && !dialogVisible" >
            <router-view/>
        </component>

        <DialogUi
            v-model:show="dialogVisible"
            :width="dialog.width"
            :height="dialog.height"
            :padding="dialog.padding"
            :background="dialog.background"
            :close="dialog.close"
        >
            <news-item  :is-admin="false" :item="item" :key="item.id" v-for="item in news"></news-item>
        </DialogUi>
    </div>
</template>

<style lang="scss">
@import "@/assets/css/normalize.css";
@import "@/assets/css/style.css";
@import 'floating-vue/dist/style.css';
</style>
<script>
import AuthLayout from "@/layouts/AuthLayout.vue";
import MainLayout from "@/layouts/MainLayout.vue";
import DialogUi from "@/components/UI/DialogUI.vue";
import NewsItem from "@/components/news/news-item.vue";
import axios from "axios";
import {Notifications} from "@kyvg/vue3-notification";

export default {
    data() {
        return {
            isLoaded: false,
            dialog: {
                width: 716,
                height: 633,
                padding: 20,
                close: true
            },
            dialogVisible: false,
            token: '',
            news: [],
        }
    },
    mounted() {
        if (window.location.pathname === '/') {
            this.isLoaded = true;
            return;
        }

        const user = JSON.parse(localStorage.getItem('user'));
        if (!window.location.pathname.includes('login') && !user) {
            window.location.href = "/login";
            return;
        }
       if (user) {
           this.token = user.token;
           this.getSettings()
       } else {
           this.isLoaded = true;
       }
    },
    computed: {
        layout() {
            return (this.$route.meta.layout || 'login') + '-layout';
        }
    },
    watch: {

    },
    components: {
        Notifications,
        NewsItem,
        DialogUi,
        AuthLayout, MainLayout
    },
    methods: {
        async getSettings() {
            const time = localStorage.getItem('lastCheckNews');

            if (!time) {
                localStorage.setItem('lastCheckNews', Math.floor(Date.now() / 1000));
            }

            try {
                const {data} = await axios.get(process.env.VUE_APP_BASE_URL + 'settings?lastCheckNews=' + time, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                        token: this.token
                    }
                });
                this.newsCount = data.newsCount;
                this.news = data.news;
                this.dialogVisible = data.news?.length;
                this.$store.dispatch("setSettings", data);
                this.isLoaded = true;
                localStorage.setItem('lastCheckNews', Math.floor(Date.now() / 1000));
            } catch (e) {
                localStorage.removeItem('user');
                location.href = '/';
            }

        }
    }
}
</script>
