import {createStore} from "vuex";
import { auth } from "@/store/auth.module";
import AuthService from "@/services/auth.service";

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? { status: { loggedIn: true }, user, settings: null }
    : { status: { loggedIn: false }, user: null, settings: null };

export default createStore({
  modules: {
    auth: auth
  },
  state: () => ({
    status: { loggedIn: false },
    initialState: initialState,
  }),
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
          user => {
            commit('loginSuccess', user);
            return Promise.resolve(user);
          },
          error => {
            commit('loginFailure');
            return Promise.reject(error);
          }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
          response => {
            commit('registerSuccess');
            return Promise.resolve(response.data);
          },
          error => {
            commit('registerFailure');
            return Promise.reject(error);
          }
      );
    },
    setSettings({ commit }, settings) {
      commit('setSettings', settings);
    },
    resetNews({ commit }) {
      commit('resetNews');
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
    setSettings(state, settings) {
      state.settings =  settings;
    },
    resetNews(state) {
      state.settings.newsCount =  0;
    }
  }
})
