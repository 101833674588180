import { createApp } from 'vue'
import App from '@/App.vue'
import components from '@/components/UI';
import router from "@/router";
import directives from '@/directives';
import store from './store';
import moment from 'moment-timezone'
import FloatingVue from 'floating-vue'
import GenerateFrame from "@/components/setting/GenerateFrame.vue";
import Notifications from '@kyvg/vue3-notification'

moment.tz.setDefault('Russia/Moscow');

const app = createApp(App)

components.forEach(component => {
    app.component(component.name, component)
})

directives.forEach(directive => {
    app.directive(directive.name, directive)
})

app
    .use(router)
    .use(store)
    .use(FloatingVue)
    .use(Notifications)
    .component('frame', GenerateFrame)
    .mount('#app');
